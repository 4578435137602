import { useMediumFromMediaQuery } from '@madpaws/design-system';
import dynamic from 'next/dynamic';

import {
  FEATURE_HOLD_OUT_SPLIT_ID,
  FEATURE_HOLD_OUT_SPLIT_ID_DYNAMIC_CONFIG,
  FEATURE_HOLD_OUT_SPLIT_ID_ID_VALUES,
} from '~/components/SplitIO/constants';
import { useSplitIO } from '~/components/SplitIO/useSplitIO';

import { HowDoesMadPawsWork as HowDoesMadPawsWorkNew } from './components/new/HowDoesMadPawsWork';

import type { ReactElement } from 'react';

const HowDoesMadPawsWorkDesktop = dynamic(() =>
  import('./components/desktop/HowDoesMadPawsWork').then((module) => module.HowDoesMadPawsWork)
);
const HowDoesMadPawsWorkMobile = dynamic(() =>
  import('./components/mobile/HowDoesMadPawsWork').then((module) => module.HowDoesMadPawsWork)
);

type Props = {
  isHero?: boolean;
};

export const HowDoesMadPawsWork = ({ isHero }: Props): ReactElement => {
  const { getDynamicConfigEnabled } = useSplitIO();
  const isMediumFromViewport = useMediumFromMediaQuery();

  const isHomePageComponentChangesEnable = getDynamicConfigEnabled(
    FEATURE_HOLD_OUT_SPLIT_ID,
    FEATURE_HOLD_OUT_SPLIT_ID_ID_VALUES.ON,
    FEATURE_HOLD_OUT_SPLIT_ID_DYNAMIC_CONFIG.HOME_PAGE_COMPONENT_CHANGES
  );

  if (isHomePageComponentChangesEnable) {
    return <HowDoesMadPawsWorkNew isHero={isHero} />;
  }

  return isMediumFromViewport ? <HowDoesMadPawsWorkDesktop /> : <HowDoesMadPawsWorkMobile />;
};
