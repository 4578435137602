import {
  Button,
  Heading,
  IconPlay,
  useMediumFromMediaQuery,
  useLargeFromMediaQuery,
} from '@madpaws/design-system';

import { trackEvent } from '~/components/analytics/analytics';
import { CLICKED_HOW_IT_WORKS } from '~/components/analytics/constants';

import styles from './HowDoesMadPawsWork.module.css';
import { SuccessFlow } from './SuccessFlow/SuccessFlow';
import { HMPW_YOUTUBE_LINK, HOW_DOES_MADPAWS_WORKS_HTML_ID } from '../constants';

import type { ReactElement } from 'react';

type Props = {
  isHero?: boolean;
};

export const HowDoesMadPawsWork = ({ isHero }: Props): ReactElement => {
  const isMediumFromViewport = useMediumFromMediaQuery();
  const isLargeFromViewport = useLargeFromMediaQuery();

  const handleClick = (): void => {
    trackEvent(CLICKED_HOW_IT_WORKS);
    window?.open(HMPW_YOUTUBE_LINK, '_blank');
  };

  return (
    <section
      className={`${styles.root} ${isHero && styles.hero}`}
      id={HOW_DOES_MADPAWS_WORKS_HTML_ID}
    >
      <Heading
        alignment="centerAlign"
        fontFamily="heading"
        size={isLargeFromViewport ? 'large1x' : isMediumFromViewport ? 'medium' : 'small1x'}
      >
        How Mad Paws works
      </Heading>
      <Button
        iconTrailing={<IconPlay />}
        label="Check out how it works"
        onClick={handleClick}
        size="large1x"
        variant="tertiary"
      />
      <SuccessFlow />
    </section>
  );
};
