import getConfig from 'next/config';

import { ImageWithFallback } from '~/common/components/ImageWithFallback/ImageWithFallback';

import styles from './SuccessFlow.module.css';
import { SUCCESS_FLOW } from '../../constants';

import type { ReactElement } from 'react';

const { publicRuntimeConfig } = getConfig();

export const SuccessFlow = (): ReactElement => (
  <div className={styles.root}>
    {SUCCESS_FLOW.map(({ title, description, image, imageFallback }, index) => (
      <div className={styles.rootStep} key={index}>
        <div className={styles.step}>
          <div className={styles.titleContainer}>
            <div className={styles.number}>{index + 1}</div>
            <div className={styles.title}>{title}</div>
          </div>
          <p className={styles.description}>{description}</p>
        </div>
        <ImageWithFallback
          alt={`${title} company image`}
          fallback={`${publicRuntimeConfig.staticPath}${imageFallback}`}
          webpSrc={`${publicRuntimeConfig.staticPath}${image}`}
        />
      </div>
    ))}
  </div>
);
